import { useProfile } from "../../hooks/useProfile"
import useAuthStore from "../../stores/AuthStore"
import usePageLayoutStore from "../../stores/PageLayoutStore"
import { Navigate, Outlet } from "react-router-dom"
import Sidebar from "../Sidebar/Sidebar"
import ModalBase from '../ModalBase/ModalBase'
import "./Main.css"

const Main = () => {

    const { isLogged } = useAuthStore((state) => ({
        isLogged: state.isLogged
    }))

    const { isMaintenance } = usePageLayoutStore((state) => ({
        isMaintenance: state.isMaintenance
    }))
    
    const { data } = useProfile()

    const isKyc = data?.kyc_status === "green"

    return (
        <>
            {!isMaintenance ? 
                isLogged ?
                    data ? 
                        isKyc ? 
                            <div className="horizontal-divide">
                                <Sidebar />
                                <Outlet />
                                <ModalBase />
                            </div>
                        :
                            <Navigate to="/kyc" />
                    :
                        <div></div>
                :
                    <Navigate to="/login" />
            :
                <Navigate to="/maintenance" />
            }
        </>
    )
}

export default Main