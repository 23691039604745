import Spinner from "../../components/Spinner/Spinner"
import "./LoadingModal.css"

const LoadingModal = () => {
    return (
        <div className="loading-modal-cont">
            <Spinner />
        </div>
    )

}

export default LoadingModal