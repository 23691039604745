import btc from "../assets/coins/btc.svg"
import usdt from "../assets/coins/usdt.svg"
import eth from "../assets/coins/eth.svg"
import cop from "../assets/coins/cop.svg"
import pen from "../assets/coins/pen.svg"
import usd from "../assets/coins/usd.svg"
import all from "../assets/coins/all.svg"

const coinlogos = {
    BTC: btc,
    USDT: usdt,
    COP: cop,
    ETH: eth,
    PEN: pen,
    USD: usd,
    ALL: all,
}

export default coinlogos