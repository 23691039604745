import axiosInstance from "../api/axiosInstance"
import { useQuery } from "@tanstack/react-query"

const fetchLimits = () => {
    return axiosInstance.get("/limits")
}

export const useLimits = () => {
    return useQuery({
        queryKey: ['limits'],
        queryFn: fetchLimits,
        select: (data) => {
            return data.data
        }
    })
}