import { useState } from "react"
import { Navigate, useParams, useNavigate, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useAuthStore from "../../stores/AuthStore"
import { FaCheckCircle } from "react-icons/fa";
import PasswordEyeInput from "../../components/PasswordEyeInput/PasswordEyeInput";
import toast from "react-hot-toast"
import logo from "../../assets/logos/logo.svg"
import "./ResetPassword.css"

const ResetPassword = () => {

    const { t } = useTranslation('common')

    const nav = useNavigate()

    const { token, uid } = useParams()

    const [showPasswordResetSuccessScreen, setShowPasswordResetSuccessScreen] = useState(false)

    const [passwords, setPasswords] = useState({new_password1: "", new_password2: ""})


    const handleChangePasswords = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswords((prev) => {
            return {...prev, [e.target.name]: e.target.value}
        })
    }

    const { isLogged, isLoading, resetPassword } = useAuthStore((state) => ({
        isLogged: state.isLogged,
        isLoading: state.isLoading,
        resetPassword: state.resetPassword,
    }))


    const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        for(let i = 0; i < conditions.length; i++) {
            if(!conditions[i].regex.test(passwords.new_password1)) {
                toast.dismiss()
                toast.error(t('hinting_regex_error'))
                return
            }
        }
        if (passwords.new_password1 !== passwords.new_password2) {
            toast.dismiss()
            toast.error(t("password_mismatch"))
            return
        }
        const res = await resetPassword({...passwords, token: token ? token : "", uid: uid ? uid : ""})
        if(res === "success") {
            setShowPasswordResetSuccessScreen(true)
        }
        else if(res === "invalid") {
            nav("/reset-password")
        }  
    }

    const conditions = [
        { label: t("password_too_short"), regex: /.{8,}$/},
        { label: t("password_needs_uppercase"), regex: /[A-Z]/},
        { label: t("password_needs_lowercase"), regex: /[a-z]/},
        { label: t("password_needs_number"), regex: /[0-9]/},
        { label: t("password_needs_special"), regex: /[!@#$%^&*(),.?":{}|<>_\-+=\[\]\\\/`~;]/},   
    ]

    return (
        <>
            {isLogged ? (
                <Navigate to="/" />
            ) : (
                <div className="reset-password-main-cont">
                    <img className="not-logged-logo" src={logo} />
                    <h1 className="reset-password-title">{t('reset_password')}</h1>
                    <div className="reset-password-hr"></div>
                    {showPasswordResetSuccessScreen ?   
                        <div className="password-reset-email-instructions">
                            <FaCheckCircle className="checkcircle"/>
                            <span>{t('password_reset_success_text')}</span>
                            <div className="register-links-cont">
                            <Link className="register-link" to="/login">{t('back_to_login')}</Link>
                    </div>
                        </div>
                    :
                        <form className="reset-password-form" onSubmit={handleResetPassword}>
                            <div className="reset-password-form-item-cont">
                                <label className="reset-password-input-label" htmlFor="new_password">{t('new_password')}</label>
                                <PasswordEyeInput notLogged={true} i_value={passwords.new_password1} i_placeholder="new_password_placeholder" i_name="new_password1" i_id="new_password1" handleChangeForm={handleChangePasswords} hasHinting={true} hintingConditions={conditions} />
                            </div>
                            <div className="reset-password-form-item-cont">
                                <label className="reset-password-input-label" htmlFor="confirm_new_password">{t('confirm_new_password')}</label>
                                <PasswordEyeInput notLogged={true} i_value={passwords.new_password2} i_placeholder="new_confirm_password_placeholder" i_name="new_password2" i_id="new_password2" handleChangeForm={handleChangePasswords} />
                            </div>
                            <button disabled={isLoading} className="login-button">{t('reset_password')}</button>
                        </form>
                    }
                </div>
            )}
        </>
    );
}

export default ResetPassword