import axiosInstance from "../api/axiosInstance"
import useAuthStore from "../stores/AuthStore"
import { useQuery } from "@tanstack/react-query"

const fetchBalance = () => {
    return axiosInstance.get("/balance")
}

export const useBalance = () => {

    const { isLogged } = useAuthStore((state) => {
        return {
            isLogged: state.isLogged
        }
    })


    return useQuery({
        queryKey: ['balance'],
        queryFn: fetchBalance,
        enabled: isLogged,
        select: (data) => {
            return data.data
        },
        refetchInterval: 5000,
    })
}