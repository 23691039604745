import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import "./InfoModal.css"

const InfoModal = ({ data }: { data: { [key: string]: any } }) => {

    const { t } = useTranslation('common')

    const nav = useNavigate()

    const handleButtonClick = () => {
        nav(data?.action_url? data.action_url : "/");
    };

    return (
        <div className="info-modal-cont">
            {data?.title && (<h3 className="info-modal-title">{data.title}</h3>)}
            {data?.info && (<p>{data.info}</p>)}
            {data?.description && (<p>{data.description}</p>)}
            {data?.link && (
                <a href={data.link} target="_blank" className="info-modal-link">{t("click_here")}</a>
            )}

            {data?.action && (
                <div className="info-modal-button-section">
                    <button
                    type="button"
                    className="info-modal-button"
                    onClick={handleButtonClick}
                    >
                        {data.action}
                    </button>
                </div>
            )}
        </div>
    )

}

export default InfoModal