import { useState, useEffect } from "react";
import { numberWithCommas } from "../../utils/functions"
import { useTranslation } from "react-i18next"
import { useDetectClickOutside } from 'react-detect-click-outside'
import { FaChevronDown } from "react-icons/fa6"
import coinlogos from "../../utils/coinlogos";
import "./SelectAdvanced.css";

interface ItemDict {
  [key: string]: {
    name: string;
    description?: string;
    price?: number;
    logo?: string;
    decimals?: number;
  };
}

interface SelectAdvancedProps {
  value: string;
  items: ItemDict;
  image: boolean;
  label?: string;
  onChange: (selectedValue: string) => void;
}

const SelectAdvanced = ({ value, items, image, label, onChange }: SelectAdvancedProps) => {
  const [selected, setSelected] = useState<string>(value);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [canOpen, setCanOpen] = useState<boolean>(true);

  const { t } = useTranslation('common')

  const closeDropdown = () => {
    setIsOpen(false)
  }
  
  const dropdownref = useDetectClickOutside({ onTriggered: closeDropdown })

  useEffect(() => {
    if(value) {
      onChange(value);
      setSelected(value);
    }
  }, [value]);

  const handleSelect = (id: string) => {
    setSelected(id);
    setIsOpen(false);
  };

  useEffect(() => {
    if(selected)
      onChange(selected);
  },[selected])

  useEffect(() => {
    setCanOpen(Object.keys(items).length > 0)

    if(Object.keys(items).length === 1){
      setSelected(Object.keys(items)[0])
      setCanOpen(false)
    }
  },[items])

  return (
    <div ref={dropdownref} className="advance-selector-main-cont">
        {label && (<div className="advance-selector-label">{label}</div>)}
        <div className="advance-selector-input-cont">
          <div className={`advance-selector-current ${canOpen ? "advance-selector-current-pointer" : ""} ${isOpen && canOpen ? "advance-selector-current-open" : ""}`} onClick={() => setIsOpen((prev) => !prev)}>
            {
              selected && items[selected] && (t(items[selected].name) || items[selected].name) ? (
                <div className="advance-selector-left">
                  {selected && image && (
                    <img
                      src={items[selected]?.logo || coinlogos[selected as keyof typeof coinlogos]}
                      alt={items[selected]?.name}
                      className="advance-selector-img"
                      width="20"
                      height="20"
                    />
                  )}
                  <span className="advance-selector-name">
                    { (t(items[selected].name) || items[selected].name)}
                  </span>
                  { items[selected].description && 
                  <span className="advance-selector-description">
                    {(t(`${items[selected].description}`) || items[selected].description)}
                  </span>
                  }
                </div>
              ) : (
                <span className="advance-selector-no-name">
                  { canOpen ? t("select_option") : t("no_option")}
                  </span>
              )
            }
            <div className="advance-selector-info">
              {items[selected]?.price != null && (
                <span className={`advance-selector-price ${items[selected]?.price === 0 ? "no-balance-title" : ""}`}>
                  {items[selected]?.price === 0 ? t("no_balance") :numberWithCommas(items[selected]?.price, items[selected]?.decimals)}
                </span>
              )}
              { canOpen && <FaChevronDown className="coin-selector-chevron" />}
            </div>
          </div>
      {isOpen && canOpen && (
        <ul className="advance-selector-options" >
          {Object.entries(items).filter(([id]) => id !== selected).map(([id, item], i) => (
            <li key={id} onClick={() => handleSelect(id)} className={`advance-selector-elem ${i !== Object.keys(items).length - 1  ? "advance-bottom-border" : ""}`}>
              <div className="advance-selector-left">
                {image && (
                  <div>
                  <img
                    src={item?.logo || coinlogos[id as keyof typeof coinlogos]}
                    alt={item?.name}
                    className="advance-selector-img"
                    width="20"
                    height="20"
                  />
                  </div>
                )}
                <span className="advance-selector-name">
                  {(t(`${item?.name}`) || item.name)}
                </span>
                { item.description && 
                  <span className="advance-selector-description">
                    {(t(`${item?.description}`) || item.description)}
                  </span>
                }
              </div>
              {item?.price != null && (
                <span className={`advance-selector-price ${item.price === 0 ? "no-balance-title" : ""}`}>
                  {item.price === 0 ? t("no_balance") :numberWithCommas(item.price, item.decimals)}
                </span>
              )}
          </li>
          ))}
        </ul>
      )}
        </div>

    </div>
  );
};

export default SelectAdvanced;
