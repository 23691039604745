import axiosInstance from "../api/axiosInstance"
import { useQuery } from "@tanstack/react-query"

const fetchCards = () => {
    return axiosInstance.get("/cards")
}

export const useCards = () => {
    return useQuery({
        queryKey: ['cards'],
        queryFn: fetchCards,
        select: (data) => {
            return data.data
        }
    })
}