import axios, { AxiosResponse } from "axios"
import useAuthStore from "../stores/AuthStore"
import usePageLayoutStore from "../stores/PageLayoutStore"
import localConfig from '../../src/local_config'

const axiosInstance = axios.create({
    baseURL: localConfig.api,
})

const noAuthRequireApis = [
    "/coins",
    "/limits",
    "/pairs",
    "/otcprice",
    "/stats",
    "/auth/login/",
    "/auth/token/refresh/",
    "/auth/registration/",
    "/auth/registration/verify-email/",
    "/auth/password/reset/",
    "/check2falogin/",
    "/checkgooglecode/",
    "/check_invite/",
    "/pairs_volume/",
    "/check_captcha/",
    "/auth/password/reset/confirm/",
]

  
axiosInstance.interceptors.request.use(
    async (config) => {
        config.headers = config.headers || {}
        config.headers["X-API-LANG"] = localStorage.getItem("language") || "es"

        const getAccessToken = useAuthStore.getState().getAccessToken
        if (!noAuthRequireApis.includes(config.url || "")) {
            const token = await getAccessToken()
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`
            }
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        const setIsMaintenance = usePageLayoutStore.getState().setIsMaintenance
        setIsMaintenance(false)
        return response
    },
    async (error) => {
        const originalRequest = error.config
        const getAccessToken = useAuthStore.getState().getAccessToken
        const logOut = useAuthStore.getState().logOut
        const setIsMaintenance = usePageLayoutStore.getState().setIsMaintenance
        if(error.response?.status === 401 && !noAuthRequireApis.includes(originalRequest.url || "") && originalRequest.url !== "/auth/token/refresh/") {
            if(error.response?.data?.code?.message === "user_not_found") {
                logOut()
            }
            else {
                try {
                    const newToken = await getAccessToken()
                    if (newToken) {
                        originalRequest.headers["Authorization"] = `Bearer ${newToken}`
                        return axiosInstance(originalRequest)
                    }
                } catch (err) {
                    console.log("Token refresh failed", err)
                    logOut()
                }
            }
        }
        else if(error.response?.status === 503) {
            setIsMaintenance(true)
        }
        return Promise.reject(error)
    }
)

export default axiosInstance
