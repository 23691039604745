import { useState } from "react";
import { useTranslation } from "react-i18next"
import { usePostPaymentAccount } from "../../hooks/usePaymentAccounts";
import Inswitch from "../../components/Inswitch/Inswitch";
import SelectAdvanced from "../../components/SelectAdvanced/SelectAdvanced";
import useModalStore from "../../stores/ModalStore"
import cloneDeep from 'lodash/cloneDeep';
import toast from "react-hot-toast";
import "./PaymentAccountModal.css"
import Spinner from "../../components/Spinner/Spinner";

interface PaymentAccountCreationProps {
    ticker: string;
    coin: { [key: string]: any };
  }
  

const PaymentAccountCreation = ({ ticker, coin } : PaymentAccountCreationProps) => {

    const { t } = useTranslation('common')
    const { mutateAsync: paymentAccountRequest } = usePostPaymentAccount();

    const [formData, setFormData] = useState<{ [key: string]: any }>({});
    const [ isLoading, setIsLoading] = useState(false);

    const { closeModal } = useModalStore((state) => {
        return {
            closeModal: state.closeModal,
        }
    })

    const [defaultFields, setDefaultFields] = useState([
        {
            "description": "Nombre de la cuenta",
            "fieldType": "input",
            "isUserField": true,
            "mandatory": true,
            "name": "display_name",
            "regex": ".*",
            "validOptions": [],
            "value": "",
            "handleInputChange": (value: string) => {
                setDefaultFields((prev) => {
                    let clone = cloneDeep(prev)
                    clone[0].value = value
                    return clone
                })
            }
        },
        {
            "description": "Descripción",
            "fieldType": "input",
            "isUserField": true,
            "mandatory": false,
            "name": "descriptionText",
            "regex": ".*",
            "validOptions": [],
            "value": "withdrawal"
        },
        {
            "description": "Moneda",
            "fieldType": "input",
            "isUserField": true,
            "mandatory": false,
            "name": "currency",
            "regex": ".*",
            "validOptions": [],
            "value": ticker
        }
    ]);

    const blockchainList = () => {
        return coin.blockchain_list.length > 0
            ? coin.blockchain_list.reduce((acc : { [key: string]: any }, item: string) => {
                acc[item] = { name: item, id: item };
                return acc;
            }, {})
            : { [ticker]: { name: ticker } };
        };

    const handleFormDataChange = (data: object) => {
        setFormData(data);
    };

    const validateFormData = (data: { [key: string]: any }) => {
        return Object.values(data).every(value => value !== "");
    };
    

    const handleInputChange = (field: string, value: string) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    }
    
    
    const handlePaymentAccountCreation = async () => {
        try {
            setIsLoading(true)
            const inputFields =  {
                ...Object.fromEntries(
                    defaultFields.map(defaultField => [defaultField.name, defaultField.value]) // Crea un objeto de pares [name, value]
                ),
                ...formData,

            }
            const payload = {
                display_name: inputFields.display_name, 
                blockchain: formData.blockchain, 
                currency: ticker, 
                fields: inputFields
            };

            if(coin.is_fiat) 
            {
                if(!validateFormData({...formData.data}))
                    throw { message: t("missing_form_fields"), status: 400 };
            }
            else {
                if(!formData.address || formData.address === ""){
                    throw { message: t("missing_form_fields"), status: 400};
                }
            }
            const result = await paymentAccountRequest(payload);
            setIsLoading(false)

            if(result.status == 201){
                toast.dismiss()
                toast.success(t("payment_account_created"))
                closeModal()
            }
        } catch (error: any) {
            setIsLoading(false)
            toast.dismiss()
            console.error("Error al hacer la solicitud a la API:", error);
            if(error?.status === 400)
                toast.error(t("missing_form_fields"))
            else
                toast.error(t("unexpected_error"))            
        }
        
    };


    return (
        <div className="payment-account-creation-modal">
            <div className="create-payment-title">
                {t("payment_account_modal.title")}
            </div>
            { coin && coin.is_fiat ? (
                <Inswitch
                    action="out"
                    currency={ticker}
                    onFormDataChange={handleFormDataChange}
                    defaultFields={defaultFields}
                />
            ) : (
                    <div className="payment-field-container">

                        <div className="payment-account-field">
                            <div>{t("display_name")}</div>
                            <input 
                                className="payment-account-field-value"
                                onChange={(e) => {
                                    handleInputChange("display_name", e.target.value);
                                }}
                                placeholder={t("insert") + (t("display_name")).toLowerCase()}
                                
                            />
                        </div>
                        <SelectAdvanced
                            label={t("select_blockchain")}
                            value={""}
                            items={blockchainList()}
                            image={false}
                            onChange={(e) => {
                                handleInputChange("blockchain", e);
                            }}
                        />
                        <div className="payment-account-field">
                            <div>{t("address")}</div>
                            <input 
                                className="payment-account-field-value"
                                onChange={(e) => {
                                    handleInputChange("address", e.target.value);
                                }}
                                placeholder={t("insert") + (t("address")).toLowerCase()}

                            />
                        </div>
                    </div>
                )
            }
                <div className="payment-account-button-div">
                {
                    isLoading ? <Spinner /> :
                        <button className="payment-account-action-button" onClick={handlePaymentAccountCreation}>
                            {t("continue")}
                        </button>
                }
                </div>
        </div>
    )

}

export default PaymentAccountCreation