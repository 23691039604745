import { useEffect, useRef, useState } from 'react';
import { useProfile } from "../../hooks/useProfile";
import { useTranslation } from 'react-i18next';
import snsWebSdk from '@sumsub/websdk';
import i18n from '../../i18n';
import './LaunchWebSdk.css';

interface Props {
  accessToken: string;
  getNewAccessToken: () => Promise<string>;
}

const LaunchWebSdk = ({ accessToken, getNewAccessToken }: Props) => {
  const { refetch } = useProfile();
  const [statusCompleted, setStatusCompleted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!containerRef.current) return;

    const snsWebSdkInstance = snsWebSdk
      .init(accessToken, getNewAccessToken)
      .withConf({
        lang: i18n.language,
        uiConf: {
          customCssStr: "span.title {color: white !important}"
        }
      })
      .withOptions({
        addViewportTag: false,
        adaptIframeHeight: true,
      })
      .on('idCheck.onApplicantStatusChanged', (status: any) => {
        const reviewStatus = status.reviewStatus;
        console.log(`Status: ${reviewStatus}`);
        if (reviewStatus === 'completed') {
          setStatusCompleted(true);
        }
      })
      .build();

    snsWebSdkInstance.launch(containerRef.current);

    return () => {
      snsWebSdkInstance.destroy();
    };
  }, [accessToken, getNewAccessToken, i18n.language]);

  const handleButtonClick = async () => {
    await refetch();
  };
  
  return (
    <div className="kyc-main-cont">
      <div className="sdk-container" ref={containerRef}>
        {statusCompleted &&  ( 
          <div className="button-redirect-home" onClick={() => {handleButtonClick()}}>
            {t('take_me_nemo')}
          </div>
        )}
      </div>
    </div>
  );
};

export default LaunchWebSdk;

