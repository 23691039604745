import axiosInstance from "../api/axiosInstance"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

const fetchPaymentAccount = () => {
    return axiosInstance.get("/payment_account")
}

const postPaymentAccount = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/payment_account/", formData);
}

const deletePaymentAccount =  async (id: string) => {
    return axiosInstance.delete(`/payment_account/${id}`);
}

export const usePaymentAccount = () => {
    return useQuery({
        queryKey: ['payment-account'],
        queryFn: () => fetchPaymentAccount(),
        select: (data) => {
            return data.data
        }
    })
}

export const usePostPaymentAccount = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postPaymentAccount(formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payment-account'] })
        }
    });
}

export const useDeletePaymentAccount = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (id: string) => deletePaymentAccount(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payment-account'] })
        }
    });
}

