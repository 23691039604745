import useModalStore from "../../stores/ModalStore";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./ModalBase.css";

const ModalBase = () => {
    
    const {isModalOpen, closeModal, modalContent} = useModalStore((state) => {
        return {
            isModalOpen: state.isModalOpen,
            closeModal: state.closeModal,
            modalContent: state.modalContent
        }
    })

    const location = useLocation()

    const handleOverlayClick = (e : any) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    useEffect((() => {
        closeModal()
    }), [location])

    if (!isModalOpen) return null

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                {modalContent && <div>{modalContent}</div>}
            </div>
        </div>
    )
}

export default ModalBase;