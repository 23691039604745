import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useBalance } from "../../hooks/useBalance"
import { useCoins } from "../../hooks/useCoins"
import { numberWithCommas } from "../../utils/functions"
import coinlogos from "../../utils/coinlogos"
import { FiArrowDownRight, FiArrowUpLeft } from "react-icons/fi"
import "./BalanceSummary.css"



const BalanceSummary = () => {

    const { t } = useTranslation('common') 

    const { data: balanceData } = useBalance()
    const { data: coinsData } = useCoins()
    let coinsBalanceElems: JSX.Element[] = [] 

    balanceData && Object.keys(coinsData?.coins || []).forEach((coin) => {
        coinsBalanceElems.push(
            <div key={coin} className="balance-summary-coin-item-cont">
                <div style={{justifyContent: "flex-start"}} className="balance-summary-coin-elems-reg-cont">
                    <img className="balance-summary-coin-logo" src={coinlogos[coin as keyof typeof coinlogos]} />
                    <span className="balance-summary-coin-name">{coin}</span>
                </div>
                <div className="balance-summary-coin-elems-reg-cont">
                    <span>{numberWithCommas(`${balanceData.balance[coin].actual + balanceData.balance[coin].orders}`,coinsData?.coins[coin]?.decimals)}</span>
                </div>
                <div className="balance-summary-coin-elems-reg-cont">
                    <Link className="balance-summary-coin-link" to={`/deposit/${coin}`}><FiArrowDownRight /></Link>
                    <Link className="balance-summary-coin-link" to={`/withdraw/${coin}`}><FiArrowUpLeft /></Link>
                </div>
            </div>
        )
    })


    return (
        <div className="balance-summary-main-cont">
            <div className="balance-summary-label-elems-cont">
                <div style={{justifyContent: "flex-start"}} className="balance-summary-reg-label">{t("coin")}</div>
                <div className="balance-summary-reg-label">{t("available")}</div>
                <div className="balance-summary-reg-label">{t("actions")}</div>
            </div>
            <div className="balance-summary-coins-elems-cont">
                {coinsBalanceElems}
            </div>
        </div>
    )
}

export default BalanceSummary