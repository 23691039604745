import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"
import { numberWithCommas, getRegularNumber } from "../../utils/functions"
import "./FeeLimits.css";

interface FeeLimitsProps {
    ticker: string;
    amount?: number;
    minLimit: number;
    maxLimit: number;
    fee?: number;
    minFee?: number;
    isPending?: boolean,
    flat? : boolean;
    onLimitCheck: (isValid: boolean) => void;
    buttonAction?: () => void;
}

const FeeLimits = ({ ticker, isPending=false, amount = 0, minLimit, maxLimit, fee = 0, minFee = 0, flat = true, onLimitCheck, buttonAction }: FeeLimitsProps) => {
    
    const { t } = useTranslation('common');
    const [ onLimit, setOnLimit ] = useState(false)
    const [calculatedAmount, setCalculatedAmount] = useState<number>(0);

    useEffect(() => {
        const netAmount = parseFloat( minFee > (amount * fee) ? (amount - minFee).toFixed(8) : (amount - fee  * (flat ? 1 : amount)).toFixed(8));
        if (amount < minLimit || amount > maxLimit || netAmount <= 0) {
            onLimitCheck(false);
            setOnLimit(false)
        } else {
            onLimitCheck(true);
            setOnLimit(true)
        }
        setCalculatedAmount(netAmount > 0 ? netAmount : 0);
    }, [amount, fee]);

    
    return (
        <div className="fee-limits-cont">
            <div className="limits-section">
                    <label className="limit-label">{t("limits")}</label>
                    <div className="amount-value">
                    <span id="minAmount">{numberWithCommas(minLimit || 0)} - {numberWithCommas(maxLimit || 0)} {ticker}</span>
                    </div>
            </div>
            {
                fee != 0 && (
                    <div className='fee-section'>
                        <div className="fee-container">
                            <div className='fee-value'>
                                <label className='fee-value-label'> {t("fee")} </label>
                                <span>{getRegularNumber(parseFloat((flat ? fee : fee * 100).toFixed(8)))} {!flat && ("%") } {ticker} </span>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                minFee != 0 && (
                    <div className='fee-section'>
                        <div className="fee-container">
                            <div className='fee-value'>
                                <label className='fee-value-label'> {t("min_fee")} </label>
                                <span>{numberWithCommas(minFee)} {ticker} </span>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                calculatedAmount != 0 && (
                    <div className={'net-amount-div'}>
                        <div className='net-amount'>
                            <span className='net-amount-label'>
                                {t("recieve_amount")}: <span className='net-amount-value'>{numberWithCommas(calculatedAmount)} </span>
                            </span>
                        </div> 
                    </div>
                )
            }
            {
                buttonAction && (
                    <button disabled={isPending || !onLimit} className="fee-action-button" onClick={buttonAction}>
                        {t("continue")}
                    </button>
                )
            }
        </div>
    );
};

export default FeeLimits;
