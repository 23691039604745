import { HiOutlineArrowNarrowUp, HiOutlineArrowNarrowDown, HiChevronLeft, HiChevronRight, HiCheckCircle, HiCash, HiQuestionMarkCircle, HiClock, HiMinusCircle } from "react-icons/hi";
import { RiArrowUpDownLine } from "react-icons/ri";
import { useHistory } from "../../hooks/useWalletHistory";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../utils/functions"
import coinlogos from "../../utils/coinlogos";
import "./InOutHistory.css";
import CoinFilterSelector from "../CoinFilterSelector/CoinFilterSelector";
import { useCoins } from "../../hooks/useCoins";
import Spinner from "../Spinner/Spinner";


type FilterOptions = 'ALL' | 'positive' | 'negative';

interface InOutHistoryProps {
    filterDefault: FilterOptions;
}

const InOutHistory = ({filterDefault} : InOutHistoryProps) => {
    const { t } = useTranslation('common');
    const [page, setPage] = useState(0);
    const itemsPerPage = 10;
    const [filter, setFilter] = useState(filterDefault);
    const [selectedCoin, setSelectedCoin] = useState('ALL');
    const {data:coinsData} = useCoins()
    

    const statusKeys = {
        0: 'pending',
        1: 'to_be_sent',
        2: 'done',
        3: 'canceled',
        4: 'failed',
        5: 'unknown',
        6: 'wait_confirmation',
        7: 'reverted',
        8: 'verifying',
    };
    
    const iconsStatus = {
        0: <HiClock />,
        1: <HiClock />,
        2: <HiCheckCircle />,
        3: <HiMinusCircle />,
        4: <HiMinusCircle />,
        5: <HiQuestionMarkCircle />,
        6: <HiClock />,
        7: <HiCash />,
        8: <HiClock />,
    }

    const handleChangeCoin = (coin: string) => {
        setSelectedCoin(coin);
    };
    
    const params: any = {
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        ordering: '-created',
    };
    
    if (filter === 'positive') {
        params.operation_type = 1;
    } else if (filter === 'negative') {
        params.operation_type = 2;
    }
    
    if (selectedCoin !== 'ALL') {
        params.currency = selectedCoin;
    }
    
    const { data: walletHistory, isLoading } = useHistory(params);
    const currentPage = page + 1;
    const totalPagesNum = Math.ceil(walletHistory?.count / itemsPerPage) || 1;


    useEffect(() => {
        setPage(0);
    }, [filter, selectedCoin]);

    function handlePageNumber(pageToGo: number) {
        setPage(pageToGo - 1);
    }

    function handleClickForward() {
        setPage((prevPage) => Math.min(prevPage + 1, totalPagesNum - 1));
    }

    function handleClickBackward() {
        setPage((prevPage) => Math.max(prevPage - 1, 0));
    }

    function getDisplayPages(totalPagesNum: number, currentPage: number) {
        const pages = [];

        const addPageRange = (start: number, end: number) => {
            for (let i = start; i <= end; i++) {
                pages.push(i);
            }
        };

        if (totalPagesNum <= 5) {
            addPageRange(1, totalPagesNum);
        } else {
            if (currentPage <= 3) {
                addPageRange(1, 4);
                pages.push('...', totalPagesNum);
            } else if (currentPage >= totalPagesNum - 2) {
                pages.push(1, '...');
                addPageRange(totalPagesNum - 3, totalPagesNum);
            } else {
                pages.push(1, '...');
                addPageRange(currentPage - 1, currentPage + 1);
                pages.push('...', totalPagesNum);
            }
        }

        return pages;
    }

    const displayPages = getDisplayPages(totalPagesNum, currentPage);

    return (
        <div className="movements-cont">
            <h1 className="movements-title">{t("movements")}</h1>
            <div className="movements-body">
                <div className="movements-filters">
                    <div className="movements-icons">
                        <RiArrowUpDownLine size={32} onClick={() => setFilter('ALL')} style={{ cursor: 'pointer', color: filter === 'ALL' ? 'white' : '#8e9eb5' }} />
                        <HiOutlineArrowNarrowDown size={28} onClick={() => setFilter('positive')} style={{ cursor: 'pointer', color: filter === 'positive' ? 'white' : '#8e9eb5' }} />
                        <HiOutlineArrowNarrowUp size={28} onClick={() => setFilter('negative')} style={{ cursor: 'pointer', color: filter === 'negative' ? 'white' : '#8e9eb5' }} />
                    </div>
                    <div className="movements-filters-cont">
                        <CoinFilterSelector selectedCoin={selectedCoin} handleChangeCoin={handleChangeCoin} />
                    </div>
                </div>

                <div className="movements-container">
                    <div className="movements-titles-row">
                        <div className="title-movements">{t("coin")}</div>
                        <div className="title-movements">{t("amount")}</div>
                        <div className="title-movements">{t("date")}</div>
                        <div className="title-movements title-movements-status">{t("status")}</div>
                    </div>
                    <div className="apidata-movements-cont">
                        {walletHistory?.results.length > 0 ? (
                            walletHistory.results.map((item: any, index: any) => (
                                <div
                                    key={item.id}
                                    className={`inlet-apidata-movements ${index !== walletHistory.results.length - 1 ? "inlet-apidata-movements-border" : ""}`}>
                                    <div className="apidata-item-movements-currency-left">
                                        <img className="coin-logo" src={coinlogos[item.currency as keyof typeof coinlogos]} alt={item.currency} /> 
                                        <span>{item.currency}</span>
                                    </div>

                                    <div className={item.amount > 0 ? "apidata-item-movements-amounts-right positive" : "apidata-item-movements-amounts-right negative"}>
                                        { numberWithCommas(`${item.amount}`, coinsData?.coins[item.currency]?.decimals ) }
                                    </div>

                                    <div className="apidata-item-movements-date">
                                        <div className="movements-apidata-items-time">
                                            {new Date(item.created).toLocaleTimeString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: false,
                                            })}
                                        </div>
                                        <div className="movements-apidata-items-date">
                                            {new Date(item.created).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "2-digit",
                                            })}
                                        </div>
                                    </div>

                                    <div className={`apidata-item-movements-status status-${item.state}`}>
                                        {iconsStatus[item.state as keyof typeof iconsStatus]} <span className="movements-status-text">{t(`status_mapping.${statusKeys[item.state as keyof typeof statusKeys]}`)}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="movements-no-items">
                                { isLoading === true ?   <Spinner /> : <div className="nomovdiv">{t('nomovements')}</div> }
                                
                            </div>
                        )}
                    </div>
                </div>

                <div className="movements-pagination-main-cont">
                    <div className="movements-pagination-box">
                        <div className="change-state-history" onClick={handleClickBackward}>
                            <div className="arrow-pagination">
                                <HiChevronLeft size={32} />
                            </div>
                            <div className="next-previous-pagination">
                                {t("previous")}
                            </div>
                        </div>
                        {displayPages.map((numberi, index) => (
                            typeof numberi === 'number' ? (
                                <div key={index} className={`pagination-individual-page-selector ${numberi === currentPage ? 'active-page' : ''}`} onClick={() => handlePageNumber(numberi)}>
                                    {numberi}
                                </div>
                            ) : (
                                <div key={index} className="pagination-ellipsis">
                                    {numberi}
                                </div>
                            )
                        ))}
                        <div className="change-state-history" onClick={handleClickForward}>
                            <div className="next-previous-pagination">
                                {t("next")}
                            </div>
                            <div className="arrow-pagination">
                                <HiChevronRight size={32} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InOutHistory;
