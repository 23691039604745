import axiosInstance from "../api/axiosInstance"
import { useMutation, useQueryClient } from "@tanstack/react-query"

interface OtpPayload {
    code: string,
    secret: string
}

interface SecretPayload {
    secretcode: string
}

const postSecret = () => {
    return axiosInstance.post("gen_secret/")
}

const postCheck2Fa = (otppayload: OtpPayload) => {
    return axiosInstance.post("checkotp/", otppayload)
}

const postSet2Fa = (secretpayload: SecretPayload) => {
    return axiosInstance.post("set2fa/", secretpayload)
}

const postDisable2Fa = (secretpayload: SecretPayload) => {
    return axiosInstance.post("remove2fa/", secretpayload)
}

export const useSecret = () => {
    return useMutation({
        mutationFn: () => postSecret()
    })
}

export const useCheckOtp = (handleSuccess: (payload: any) => void, handleError: (payload: any) => void) => {
    return useMutation({
        mutationFn: (otppayload: OtpPayload) => postCheck2Fa(otppayload),
        onSuccess: (response) => {
            handleSuccess(response)
        },
        onError: (error) => {
            handleError(error)
        }
    })
}

export const useSet2Fa = (handleSuccess: (payload: any) => void, handleError: (payload: any) => void) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (secretpayload: SecretPayload) => postSet2Fa(secretpayload),
        onSuccess: (response) => {
            handleSuccess(response)
            queryClient.invalidateQueries({ queryKey: ['profile'] })
        },
        onError: (error) => {
            handleError(error)
        }
    })
}

export const useDisable2Fa = (handleSuccess: (payload: any) => void, handleError: (payload: any) => void) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (secretpayload: SecretPayload) => postDisable2Fa(secretpayload),
        onSuccess: (response) => {
            handleSuccess(response)
            queryClient.invalidateQueries({ queryKey: ['profile'] })
        },
        onError: (error) => {
            handleError(error)
        }
    })
}



