import axiosInstance from "../api/axiosInstance"
import { useQuery } from "@tanstack/react-query"
import useAuthStore from "../stores/AuthStore"

const fetchProfile = () => {
    return axiosInstance.get("/profile")
}

export const  useProfile = () => {

    const { isLogged } = useAuthStore((state) => {
        return {
            isLogged: state.isLogged
        }
    })

    return useQuery({
        queryKey: ['profile'],
        enabled: isLogged,
        queryFn: fetchProfile,
        refetchInterval: 5000,
        select: (data) => {
            return data.data
        },
    })
}