import { useEffect, useState } from "react";
import { useCoins } from "../../hooks/useCoins";
import { useBalance } from "../../hooks/useBalance";
import { usePaymentAccount } from "../../hooks/usePaymentAccounts";
import { usePostInswitch } from "../../hooks/useInswitch";
import { useLimits } from "../../hooks/useLimits";
import { usePostWalletWithdraw } from "../../hooks/useWallets";
import { useTranslation } from "react-i18next"
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { numberWithCommas } from "../../utils/functions"
import { FaCirclePlus } from "react-icons/fa6";
import { LuInspect } from "react-icons/lu";
import useModalStore from "../../stores/ModalStore"
import SelectAdvanced from "../../components/SelectAdvanced/SelectAdvanced";
import FeeLimits from "../../components/FeeLimits/FeeLimits";
import InOutHistory from "../../components/InOutHistory/InOutHistory";
import ConfirmationCodeModal from "../../modals/ConfirmationCodeModal/ConfirmationCodeModal"
import PaymentAccountModal from "../../modals/PaymentAccountModal/PaymentAccountModal"
import "./Withdraw.css";
import toast from "react-hot-toast";
import LoadingModal from "../../modals/LoadingModal/LoadingModal";
import PaymentAccountInfoModal from "../../modals/PaymentAccountInfoModal/PaymentAccountInfoModal";

const Withdraw = () => {
    const { coin } = useParams<{ coin: string }>();
    const { mutateAsync: withdrawalInswitchRequest } = usePostInswitch();
    const { mutateAsync: withdrawalCryptoRequest } = usePostWalletWithdraw();
    const { t } = useTranslation('common')
    const { data: coinsData } = useCoins();
    const { data: balanceData } = useBalance();
    const { data: paymentAccounts }  = usePaymentAccount();
    const { data: limits }  = useLimits();
    const [ balanceAfterWithdrawal, setBalanceAfterWithdrawal] = useState(0);
    const [ withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [selectedCoin, setSelectedCoin] = useState(coin || "COP");
    const [selectedPaymentAccount, setselectedPaymentAccount] = useState<{ [key: string]: any }>();
    const [availableBalance, setAvailableBalance] = useState(0);
    const [validAmount, setValidAmount] = useState(false);
    const [ isPending, setIsPending] = useState(false)

    const navigate = useNavigate();

    const { setModalContent, closeModal } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
            closeModal: state.closeModal,
        }
    })

    useEffect(()=>{
        setBalanceAfterWithdrawal( balanceData?.balance[selectedCoin]?.actual);
        setAvailableBalance( balanceData?.balance[selectedCoin]?.actual);
        setWithdrawalAmount(0);
    },[selectedCoin, balanceData])

    const coinsItems = () => {
        let items: {
            [key: string]: {
                name: string;
                price?: number;
                logo?: string;
                decimals?: number;
            }
        } = {};
    
        Object.keys(coinsData ? coinsData.coins : {}).forEach(key => {
            items[key] = {
                name: coinsData.coins[key].name,
                logo: coinsData.coins[key].logo,
                price: balanceData
                    ? balanceData.balance[key]
                        ? balanceData.balance[key].actual + balanceData.balance[key].orders
                        : "0"
                    : "0",
                decimals: coinsData.coins[key].decimals,
            };
        });
        
        return items;
    };
    
    const paymentAccountsItems = paymentAccounts ? 
        Object.fromEntries(
                paymentAccounts
                .filter((paymentAccount: { [key: string]: any }) => paymentAccount.currency === coin)
                .map((paymentAccount: { [key: string]: any }) => [
                    paymentAccount.display_name,
                    {
                        id: paymentAccount.display_name,
                        name: paymentAccount.display_name,
                        description: paymentAccount.blockchain,
                        data: paymentAccount.fields
                    }
                ])
        ) 
        : {};
    
    const handleCoinChange = (value: string) => {
        setSelectedCoin(value);
        setselectedPaymentAccount(undefined);
        navigate("/withdraw/"+value);
    };

    const handleSelectedPaymentAccountChange = (value: string) => {
        setselectedPaymentAccount(
            paymentAccounts.filter((paymentAccount: { [key: string]: any }) => paymentAccount.display_name === value && paymentAccount.currency === selectedCoin)[0]
        )
    }

    const handleLimitCheck = (valid: boolean) => {
        setValidAmount(valid);
    };

    const handleAmountChange = (e : any) => {
        const value = e.target.value;
        if(!value){
            setWithdrawalAmount(0);
            setBalanceAfterWithdrawal(availableBalance);
            return;
        }
        const isValidNumber = /^[0-9]*\.?[0-9]*$/.test(value);
        const numericValue = (isValidNumber ? value : 0) || 0;
        if(availableBalance > numericValue){
            setBalanceAfterWithdrawal(availableBalance - numericValue);
            setWithdrawalAmount(numericValue);
        }
        else
        {
            setBalanceAfterWithdrawal(availableBalance);
            setWithdrawalAmount(0);
        }
    }

    const handleSubmit = async () => {
        try {
            toast.dismiss()
            if(!validAmount){
                toast.error(t("limit_overflow") +
                    ` ${numberWithCommas(limits?.[selectedCoin].limits.withdrawal.min || 0, (coinsData?.coins[selectedCoin].decimals || 8))} - ${numberWithCommas(limits?.[selectedCoin].limits.withdrawal.max || 0, (coinsData?.coins[selectedCoin].decimals || 8))} ${selectedCoin}`
                );
                return;
            }
            if(!selectedPaymentAccount) {
                toast.error(t("select_payment_account"));
                return;
            }
            setIsPending(true)
            setModalContent(
                <LoadingModal />
            );
            let requestParams = {
                blockchain_currency : selectedPaymentAccount.blockchain,
                currency: selectedPaymentAccount.currency,
                payment_account: selectedPaymentAccount.id,
                amount: withdrawalAmount
            }
            if (coinsData.coins[selectedCoin].is_fiat)
            {
                const result = await withdrawalInswitchRequest({...requestParams, ...selectedPaymentAccount.fields})
                setIsPending(false)
                if (result.status === 201) {
                    setModalContent(
                        <ConfirmationCodeModal 
                            request={{ ...result.data, is_fiat: coinsData.coins[selectedCoin].is_fiat }}
                        />
                    );
                }
                else{
                    closeModal()
                    toast.error(t("withdrawal_error_address"))
                }
            }
            else{
                const result = await withdrawalCryptoRequest(requestParams)
                setIsPending(false)
                if (result.status === 201) {
                    setModalContent(
                        <ConfirmationCodeModal request={result.data} />
                    );
                }
                else{
                    closeModal();
                    toast.error(t("withdrawal_error_address"))
                }
            }
        } catch (error: any) {
            closeModal()
            setIsPending(false)
            let errorMessage = error?.response?.data?.type?.[0]?.message || t("unexpected_error");
            errorMessage = error?.response?.data?.type?.message || errorMessage;
            toast.error(t(errorMessage) != errorMessage ? t(errorMessage) : t("unexpected_error"))
            console.error("Error al hacer la solicitud a la API:", error);
        }
    };

    return (
        <>
            <div className="outlet-page-main-cont">
                <div className="withdrawal-main-cont">
                    <div className="withdrawal-withdrawal-cont">
                    <h1 className="withdrawal-title">{t("withdrawal")}</h1>
                    <div className="withdrawal-body">
                            <SelectAdvanced
                                label={t("select_coin")}
                                value={coinsData ? selectedCoin : ""}
                                items={coinsItems()}
                                image={true}
                                onChange={handleCoinChange}
                            />
                            {
                                coinsData && coinsData.coins && selectedCoin in coinsData.coins
                                    ? (
                                    <div className="withdrawal-fields-content">
                                        <div className="withdrawal-field">
                                            <div className="withdrawal-field-label">
                                                {t("available_balance")}
                                            </div>
                                            <div className="withdrawal-field-value">
                                                {numberWithCommas(availableBalance, (coinsData?.coins[selectedCoin].decimals))}
                                            </div>
                                        </div>
                                        <div className="withdrawal-field">
                                            <div className="withdrawal-field-label">
                                                {t("balance_after_withdrawal")}
                                            </div>
                                            <div className="withdrawal-field-value">
                                                {numberWithCommas(balanceAfterWithdrawal, (coinsData?.coins[selectedCoin].decimals))}
                                            </div>
                                        </div>
                                        <div className="withdrawal-field">
                                            <div className="withdrawal-field-label">
                                                {t("amount")}
                                            </div>
                                            <input 
                                                className="withdrawal-amount-value"
                                                value={withdrawalAmount || ''}
                                                onChange={(e) => {
                                                   handleAmountChange(e);
                                                }}
                                                placeholder={availableBalance === 0 ? t("no_balance") :t("insert") + (t("amount")).toLowerCase()}
                                                disabled={availableBalance === 0}
                                            />
                                        </div>
                                        
                                        <div className="payment-account-section">
                                            <div className="selectPayment">
                                                <SelectAdvanced
                                                    value = ""
                                                    label={t("select_payment_account")}
                                                    items={paymentAccountsItems}
                                                    image={false}
                                                    onChange={handleSelectedPaymentAccountChange}
                                                />
                                            </div>
                                            <div className="payment-account-action-content">
                                                <div onClick={() => {
                                                    setModalContent(
                                                        <PaymentAccountModal
                                                            ticker = {selectedCoin}
                                                            coin = {coinsData.coins[selectedCoin]}
                                                        />
                                                    )}} className="create-payment" >
                                                    <FaCirclePlus color="#8FFDFC" size={30}/>
                                                </div>
                                                <div onClick={() => {
                                                    if (!selectedPaymentAccount || selectedPaymentAccount?.type === 'card')
                                                    {
                                                        toast.dismiss()
                                                        toast.error(t("select_payment_account"))
                                                        return
                                                    }
                                                    toast.dismiss()
                                                    setModalContent(
                                                        <PaymentAccountInfoModal
                                                            fields={
                                                            selectedPaymentAccount?.type === 'fiat'
                                                                ? {...selectedPaymentAccount?.fields, ...{fields: selectedPaymentAccount?.fields.data, id: selectedPaymentAccount?.id}}
                                                                : selectedPaymentAccount?.type === 'crypto'
                                                                ? selectedPaymentAccount
                                                                : {fields: selectedPaymentAccount}
                                                            }
                                                      />
                                                   )}} className="create-payment" >
                                                    <LuInspect color="#8FFDFC" size={30}/>
                                                </div>
                                            </div>
                                        </div>
                                        <FeeLimits
                                            ticker={selectedCoin}
                                            amount={withdrawalAmount} 
                                            minLimit={limits?.[selectedCoin].limits.withdrawal.min || 0}
                                            maxLimit={limits?.[selectedCoin].limits.withdrawal.max || 0}
                                            fee={limits?.[selectedCoin].fee.withdrawal[selectedPaymentAccount?.blockchain.toUpperCase() + '_FEE']?.fee || 0}
                                            minFee={limits?.[selectedCoin].fee.withdrawal[selectedPaymentAccount?.blockchain.toUpperCase() + '_FEE']?.min || 0}
                                            flat={!coinsData?.coins[selectedCoin]?.is_fiat}
                                            onLimitCheck={handleLimitCheck}
                                            isPending={isPending}
                                            buttonAction={handleSubmit}
                                        />
                                    </div>
                                ) : null
                            }
                    </div>
                </div>
                <div className="withdrawal-history">
                    <InOutHistory filterDefault='negative' />
                </div>
                </div>
               
            </div>
        </>
    );
}

export default Withdraw;
