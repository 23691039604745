import { useProfile } from "../../hooks/useProfile"
import { useTranslation } from "react-i18next"
import ProfileItem from "../../components/ProfileItem/ProfileItem"
import ChangePassword from "../../components/ChangePassword/ChangePassword"
import NemoCard from "../../components/NemoCard/NemoCard"
import TwoFa from "../../components/TwoFa/TwoFa"
import { useCards } from "../../hooks/useCards"
import "./Profile.css"


const Profile = () => {
    const { t } = useTranslation('common')
    
    const { data: profileData } = useProfile()
    const { data: cardData } = useCards()

    const profileFields = profileData ? [
        {title: "first_name", content: profileData.user.first_name},
        {title: "last_name", content: profileData.user.last_name},
        {title: "email", content: profileData.user.username},
        {title: "country", content: profileData.country.name},
        {title: "birthday", content: new Date(profileData.birthday).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        })},
    ] : []

    const profileFieldsElems = profileFields.map((profile_item) => {
        return <ProfileItem key={profile_item.title} title={profile_item.title} content={profile_item.content} line={true} />
    })

    return (
        <>
            <div className="profile-main-cont">
                <div className="profile-main-sidebox">
                    <div className="profile-title">
                        <h1>{t("profile_header")}</h1>
                    </div>
                    <div className="profile-box">
                        <div className="content-profile-cont">
                            <div className="profile-hello-title">{t('hello_nickname')} {profileData.user.first_name}</div>
                            {profileFieldsElems}
                            {(cardData && cardData[0].active) && <>
                                <div className="profile-hello-title">{t('nemocard')}</div>
                                <NemoCard />
                            </>}
                        </div>
                    </div>
                </div>
                <div className="profile-main-sidebox">
                    <div className="profile-title">
                        <h1>{t("security")}</h1>
                    </div>
                    <div className="profile-box">
                        <div className="content-profile-cont">
                            <div className="profile-hello-title">{t('change_password')}</div>
                            <ChangePassword />
                            <div style={{marginTop: "10px"}} className="profile-hello-title">{t('two_fa')}</div>
                            <TwoFa />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )       
}

export default Profile