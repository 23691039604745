import { useState, useEffect } from 'react'
import { QRCode } from 'react-qrcode-logo'
import toast from "react-hot-toast"
import { useCheckOtp, useSecret, useSet2Fa, useDisable2Fa } from "../../hooks/use2Fa"
import { useProfile } from '../../hooks/useProfile'
import { useTranslation } from 'react-i18next'
import qrImg from "../../assets/logos/logo.svg"
import "./TwoFa.css"


const TwoFa = () => {
    
    const { t } = useTranslation('common')

    const handleCheckOtpSuccess = (payload: any) => {
        if(payload?.data?.Status) {
            setOtp("")
            return
        }
        setOtp("")
        toast.error(t('otp_failed'))
    }

    const handleCheckOtpError = (error: any) => {
        console.log(error)
        toast.error(t('error'))
    }

    const handleSet2FaSuccess = (payload: any) => {
        console.log(payload)
        if(payload?.data["2fa_enabled"]) {
            resetSecret()
            setOtp("")
            setTfaNexted(false)
            toast.success(t('tfa_setup_successful'))
            return
        }
        toast.error(t('error'))
    }

    const handleSet2FaError = (error: any) => {
        console.log(error)
        setOtp("")
        toast.error(t('error'))
    }

    const handleDisable2FaSuccess = (payload: any) => {
        console.log(payload)
        if(!payload?.data["2fa_enabled"]) {
            setDisable2Fa(false)
            setOtp("")
            toast.success(t('tfa_disable_successful'))
            return
        }
        toast.error(t('error'))
    }

    const handleDisable2FaError = (error: any) => {
        console.log(error)
        setOtp("")
        toast.error(t('otp_failed'))
    }

    const { data: secret, mutate: handleGetSecret, reset: resetSecret, isPending: isSecretPending } = useSecret()
    const { data: otpSuccess, mutate: handleSubmitOtp, isPending: isOtpCheckPending } = useCheckOtp(handleCheckOtpSuccess, handleCheckOtpError)
    const { mutate: handleSet2Fa, isPending: isSet2FaPending } = useSet2Fa(handleSet2FaSuccess, handleSet2FaError)
    const { mutate: handleDisable2Fa, isPending: isDisable2FaPending } = useDisable2Fa(handleDisable2FaSuccess, handleDisable2FaError)
    const { data: profileData } = useProfile()

    const [tfaNexted, setTfaNexted] = useState(false)
    const [disable2Fa, setDisable2Fa] = useState(false)
    const [otp, setOtp] = useState("")

    useEffect(() => {
        if(otpSuccess?.data.Status && secret) {
            handleSet2Fa({secretcode: secret?.data})
        }
    }, [otpSuccess])
    
    const nextTfa = () => {
        setTfaNexted(true)
    }

    const backTfa = () => {
        console.log("xd")
        setTfaNexted(false)
    }

    console.log((secret?.data && tfaNexted) || disable2Fa)

    return (
        <div className='tfa-main-cont'>
            {(!secret?.data && !disable2Fa) && <div className='tfa-no-process-cont'>
                <span>{profileData?.is_2fa_on ? t('two_fa_on') : t('two_fa_off')}</span>
                {profileData?.is_2fa_on ? <button onClick={() => {setDisable2Fa(true)}} className='tfa-option-button'>{t('disable')}</button> : <button  className='tfa-option-button' disabled={isSecretPending} onClick={() => {handleGetSecret()}}>{t('enable')}</button>}
            </div>}
            {secret?.data && !tfaNexted && <div className='tfa-enable-cont'>
                <QRCode value={`otpauth://totp/NEMO-2fa-${profileData.user.username}?secret=${secret.data}`} size={200} bgColor="#0F131A" fgColor="#8FFDFC" logoImage={qrImg} logoWidth={70} logoPadding={5} logoPaddingStyle="circle" removeQrCodeBehindLogo={true} ecLevel="Q"/>
                <span className='tfa-code'>{secret.data}</span>
                <span className='tfa-explanation'>{t('tfa_explanation')}</span>
{/*                     <div className='tfa-download-badges-cont'>
                    <img className='tfa-download-badge' src={t('google_download_badge')} />
                    <img className='tfa-download-badge' src={t('apple_download_badge')} />
                </div> */}
                <div className='tfa-button-options-cont'>
                    <button className='tfa-option-button' style={{backgroundColor: "#ee4747", color: "white"}} onClick={() => {resetSecret()}}>{t('cancel')}</button>
                    <button className='tfa-option-button' onClick={nextTfa}>{t('ready')}</button>
                </div>
            </div>}
            {((secret?.data && tfaNexted) || disable2Fa) && <form onSubmit={(e) => {e.preventDefault(); disable2Fa ? handleDisable2Fa({secretcode: otp}) : handleSubmitOtp({secret: secret?.data, code: otp})}} className='tfa-enable-cont'>
                <span className='tfa-opt-instructions' >{t('put_authenticator_otp')}</span>
                <input required className='tfa-opt-input' placeholder={t('tfa_otp_input_placeholder')} value={otp} onChange={(e) => {setOtp(e.target.value)}}/>
                <div className='tfa-button-options-cont'>
                    <button type='button' className='tfa-option-button' style={{backgroundColor: "#ee4747", color: "white"}} onClick={() => { disable2Fa ? setDisable2Fa(false) : backTfa() }}>{t('back')}</button>
                    <button type='submit' className='tfa-option-button' disabled={isOtpCheckPending || isSet2FaPending || isDisable2FaPending} >{t('submit')}</button>
                </div>
            </form>}

        </div>
    )
}

export default TwoFa