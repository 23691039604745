
import { useTranslation } from "react-i18next";
import { useState } from "react";
import "./InputAdvanced.css";

interface InputAdvancedProps {
    field: { [key: string]: any };
    onInputChange: (data: object, value: string) => string;
}

const InputAdvanced = ({ field, onInputChange} : InputAdvancedProps) => {
    const { t } = useTranslation('common')
    
    const [message, setMessage] = useState("")
    
    return (
        <div className="input-advanced-div">
            <div className="input-advanced-label">{t(field.name) || field.description}</div>
            <input 
                className="input-advanced-field"
                name={field.name}
                onChange={(e) => {
                    setMessage(onInputChange(field, e.target.value));
                }}
                placeholder={t("insert") + (t(field.name) || field.description).toLowerCase()}
            />
            <div className="input-advanced-field-message-disclaimer">{message}</div>
        </div>
    )
}

export default InputAdvanced;
