import { useState, useEffect } from "react"
import { useParams, useNavigate, Navigate, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useAuthStore from "../../stores/AuthStore"
import logo from "../../assets/logos/logo.svg"
import "./ConfirmEmail.css"

const initialTimer = 30

const ConfirmEmail = () => {

    const { t } = useTranslation('common')

    const { email } = useParams()

    const nav = useNavigate()

    const [timer, setTimer] = useState(0)

    const [code, setCode] = useState("")

    useEffect(() => {
        const timerclock = setInterval(() => {
            setTimer((prev) => {
                if(prev > 0) {
                    return prev - 1
                }
                else {
                    return prev
                }
            })
        }, 1000)

        return () => {
            clearInterval(timerclock)
        }
    }, [])

    const { isLogged, isLoading, resendConfirmationCode, verifyConfirmationCode } = useAuthStore((state) => ({
        isLogged: state.isLogged,
        isLoading: state.isLoading,
        resendConfirmationCode: state.resendConfirmationCode,
        verifyConfirmationCode: state.verifyConfirmationCode
    }))

    const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value)
    }

    const handleSubmitConfirmEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const success = await verifyConfirmationCode(code)
        if(success) {
            nav("/login")
        }
    }

    const handleResendCode = async () => {
        if(isLoading) {
            return
        }
        await resendConfirmationCode(email ? email : "")
        setTimer(initialTimer)
    }

    return (
        <>
            {isLogged ? (
                <Navigate to="/" />
            ) : (
                <div className="confirm-email-main-cont">
                    <img className="not-logged-logo" src={logo} />
                    <h1 className="confirm-email-title">{t('confirm_email')}</h1>
                    <div className="confirm-email-hr"></div>
                    <form className="confirm-email-form" onSubmit={handleSubmitConfirmEmail}>
                        <div className="confirm-email-form-item-cont">
                            <label className="confirm-email-input-label" htmlFor="code">{t('email_code')}</label>
                            <input required={true} value={code} className="confirm-email-input" placeholder={t("code_placeholder")} name="code" onChange={handleChangeCode} id="code" type="text" />
                        </div>
                        <button disabled={isLoading} className="login-button">{t('verify_email')}</button>
                    </form>
                    <div className="resend-code-cont">
                        <span>
                            {timer === 0 && <span onClick={handleResendCode}>{t('click_to')}</span>}
                            <span onClick={handleResendCode} className={`resend-code-text-button ${timer === 0 ? "bold" : ""}`}> {t('resend_code')}</span> 
                            {timer !== 0 && <span> {t("in")} {timer} {t('seconds')}</span>}
                        </span>
                    </div>
                    <div className="confirm-email-links-cont">
                        <Link className="confirm-email-link" to="/login">{t('back_to_login')}</Link>
                    </div>
                </div>
            )}
        </>
    );
}

export default ConfirmEmail