import { useState } from "react";
import { useTranslation } from "react-i18next"
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { 
    usePostWalletWithdrawConfirmation, 
    usePostWalletWithdrawResendConfirmation, 
    usePostWalletWithdrawCancel 
} from "../../hooks/useWallets";
import useModalStore from "../../stores/ModalStore"
import InfoModal from "../../modals/InfoModal/InfoModal"

import "./ConfirmationCodeModal.css"
import Spinner from "../../components/Spinner/Spinner";
import toast from "react-hot-toast";

const ConfirmationCodeModal =({ request }: { request: { [timerKey: string]: string } }) => {

    const { t } = useTranslation('common');
    const { mutateAsync: sendConfirmationCode } = usePostWalletWithdrawConfirmation();
    const { mutateAsync: resendConfirmationCode } = usePostWalletWithdrawResendConfirmation();
    const { mutateAsync: cancelConfirmation } = usePostWalletWithdrawCancel();
    const [ resend, setResend ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ timerKey, setTimerKey ] = useState(0);
    const [ confirmationCode, setConfirmationCode ] = useState("")
    
    const { setModalContent, closeModal } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
            closeModal: state.closeModal,
        }
    })
    const handleConfirmation = async () => {
        if (confirmationCode == "") return;
        
        try {
            setIsLoading(true)
            const result = await sendConfirmationCode({ confirmation_token: confirmationCode });
            setIsLoading(false)
        
            if (result.status === 200) {
                const { is_fiat } = request;
                const instructions = result.data.instructions
        
                const info = is_fiat 
                    ? (instructions ? t('modal_withdrawal.info') : t('modal_withdrawal.info_card'))
                    : t('modal_withdrawal.info_crypto');
                
                setModalContent(
                    <InfoModal 
                        data={{
                            title: t('modal_withdrawal.title'),
                            info: info,
                            description: instructions ? t('modal_withdrawal.description') : undefined,
                            link: instructions ? instructions : undefined,
                            action: t('modal_withdrawal.button'),
                            action_url: '/',
                        }} 
                    />
                );
        
                if (instructions) {
                    window.open(instructions, '_blank');
                }
            }
        }
        catch(error: any) {
            setIsLoading(false)
            setConfirmationCode("")
            if(error.status === 400)
                toast.error(t("invalid_confirmation_code"))
            else
            toast.error(t("unexpected_error"))   
        }

    }
    
    
    const handleResendConfirmation = async () => {
        setTimerKey(prevtimerKey => prevtimerKey + 1); 
        setResend(false);
        resendConfirmationCode({withdrawal_request_id: request.id});

    }

    const handleCancel = async () => {
        setIsLoading(true)
        const result = await cancelConfirmation({withdrawal_request_id: request.id})
        setIsLoading(false)
        if (result.status === 200) {
            closeModal();
        }
    }

    return (
        <div className="confirmation-code-cont">
           <h3 className="confirmation-code-title">{t("email_code")}</h3>
           <div className="confirmation-code-info">
            {t("email_code_text")}
           </div>
            <input
                className="confirmation-code"
                type="text"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                placeholder={t("code_placeholder")}
            />

            {
                isLoading ? <Spinner /> :
                <div className="confirmation-code-action-buttons">
                    <div className="confirmation-code-action" onClick={() => handleCancel()}>
                        {t("cancel")}
                    </div>
                    {
                        resend ? (
                            <div className="confirmation-code-action" onClick={handleResendConfirmation}>
                        {t("resend_code")}
                        </div>
                        ): (
                        <div className="timer-div">
                            <CountdownCircleTimer
                                key={timerKey}
                                isPlaying
                                size={70}
                                duration={30}
                                colors='#8FFDFC'
                                trailColor='#1C232E'
                                onComplete={() => setResend(true)}
                            >
                            {({ remainingTime }) => remainingTime}
                            </CountdownCircleTimer>
                        </div>
                        )
                    }
                    <div className="confirmation-code-action" onClick={handleConfirmation}>
                        {t("confirm")}
                    </div>
                </div>
            }
        </div>
    )

}

export default ConfirmationCodeModal