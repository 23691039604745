import { Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useProfile } from "../../hooks/useProfile";
import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import useAuthStore from "../../stores/AuthStore";
import LaunchWebSdk from "../../components/LaunchWebSdk/LaunchWebSdk";
import axiosInstance from "../../api/axiosInstance";
import toast from 'react-hot-toast'


const Kyc = () => {
  const { t } = useTranslation('common');
  const { data } = useProfile();
  const { isLogged } = useAuthStore((state) => ({
    isLogged: state.isLogged,
  }));
  
  const isKyc = data?.kyc_status === "green";
  const [accessToken, setAccessToken] = useState<string>("");

  const fetchAccessToken = async (tries:number): Promise<string> => {
    try {
      const response = await axiosInstance.post('kyc_get_access_token/');
      return response.data.token;
    }
    catch (error) {
      console.log(error)
      if (tries < 5) {
        tries++
        fetchAccessToken(tries)
      }
      toast.dismiss()
      toast.error(t('error_refresh_page'))
      throw error
    }
  };


  useEffect(() => {
    const getAccessToken = async () => {
      const token = await fetchAccessToken(0);
      setAccessToken(token);
    };
    
    getAccessToken();
  }, []);

  return (
    <>
      {isLogged ? (
        data ? (
          isKyc ? (
            <Navigate to="/" />
          ) : (
            <div className="horizontal-divide">
              <Sidebar />
              {accessToken ? (
                <LaunchWebSdk
                  accessToken={accessToken}
                  getNewAccessToken={() => fetchAccessToken(0) }
                />
              ) : (
                <div className="notLogged">Refresh Page</div>
              )}
            </div>
          )
        ) : (
          <div>isLoading?</div>
        )
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default Kyc;
