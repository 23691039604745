import { useTranslation } from "react-i18next";
import "./ProfileItem.css"

interface ProfileItemProps {
    title: string,
    content: any,
    line?: boolean
}
  

const ProfileItem = ({ title, content, line = true }: ProfileItemProps) => {
    const { t } = useTranslation('common')

    return (
        <div className="individual-item">
            <p className="title-individual-item">{t(`${title}`)}</p>
            <div className="subtitle-individual-item">{content}</div>
            {line && <div className="line"></div>}
        </div>
    );
};
  
  export default ProfileItem;
  