import { useState } from "react"
import { useCoins } from "../../hooks/useCoins"
import { useDetectClickOutside } from 'react-detect-click-outside'
import { FaChevronDown } from "react-icons/fa6"
import coinlogos from "../../utils/coinlogos"
import "./CoinFilterSelector.css"

interface CoinsProp {
    selectedCoin: string,
    handleChangeCoin: (coin: string) => void
}

const CoinFilterSelector = ({ selectedCoin, handleChangeCoin } : CoinsProp) => {

    const { data: coinsData } = useCoins()
    
    const [dropdownOpen, setDropdownOpen] = useState(false)
    
    const closeDropdown = () => {
        setDropdownOpen(false)
    }
    
    const handleToggleSelector = () => {
        setDropdownOpen((prev) => !prev)
    }

    const allcoins = coinsData ? ["ALL", ...Object.keys(coinsData.coins)] : []

    const filteredCoins = coinsData ? allcoins.filter((coin) => {
        return coin !== selectedCoin
    }) : []

    const dropdownref = useDetectClickOutside({ onTriggered: closeDropdown })

    const coinsDropdownElems = filteredCoins.map((coin, i) => {
        return (
            <div key={coin} onClick={() => {handleChangeCoin(coin); closeDropdown()}} style={{borderBottom: filteredCoins.length - 1 === i ? "none" : "1px solid #273141"}} className="filter-coin-selector-dropdown-item">
                <img className="filter-coin-selector-logo" src={coinlogos[coin as keyof typeof coinlogos]}/>
                {coin}
            </div>
        )
    })

    return (
        <div ref={dropdownref} className="filter-coin-selector-cont" style={{border: dropdownOpen ? "1px solid #8ffdfc" : "1px solid #283242"}} onClick={handleToggleSelector}>
            {selectedCoin in coinlogos && coinlogos[selectedCoin as keyof typeof coinlogos] !== "ALL" && coinlogos[selectedCoin as keyof typeof coinlogos] && (
                <img className="filter-coin-selector-logo" src={coinlogos[selectedCoin as keyof typeof coinlogos]} alt={selectedCoin} />
            )}
            <span>{selectedCoin}</span>
            <FaChevronDown className="filter-coin-selector-chevron"/>
            {dropdownOpen && <div onClick={(e) => e.stopPropagation()} className="filter-coin-selector-dropdown-cont">
                {coinsDropdownElems}
            </div>}
        </div>
    )

}

export default CoinFilterSelector