import { useState, useRef } from "react"
import { Navigate, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useAuthStore from "../../stores/AuthStore"
import { FaCheckCircle } from "react-icons/fa";
import ReCAPTCHA from 'react-google-recaptcha'
import toast from "react-hot-toast"
import logo from "../../assets/logos/logo.svg"
import "./ResetPasswordRequest.css"
import localConfig from '../../../src/local_config';

const ResetPasswordRequest = () => {

    const { t } = useTranslation('common')

    const [email, setEmail] = useState("")
    const [pendingCaptcha, setPendingCaptcha] = useState(false)
    const [showEmailSentScreen, setShowEmailSentScreen] = useState(false)

    const recaptchaRef = useRef<ReCAPTCHA | null>(null)

    const { isLogged, isLoading, sendResetPasswordRequest } = useAuthStore((state) => ({
        isLogged: state.isLogged,
        isLoading: state.isLoading,
        sendResetPasswordRequest: state.sendResetPasswordRequest,
    }))


    const handleSendResetEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setPendingCaptcha(true)
        const token = await recaptchaRef.current?.executeAsync()
        setPendingCaptcha(false)
        if(token) {
            if(await sendResetPasswordRequest(email, token)) {
                setShowEmailSentScreen(true)
            }
        }
        else {
            toast.dismiss()
            toast.error(t("captcha_failed"))
        }
    }

    return (
        <>
            {isLogged ? (
                <Navigate to="/" />
            ) : (
                <div className="reset-password-main-cont">
                    <img className="not-logged-logo" src={logo} />
                    <h1 className="reset-password-title">{t('reset_password_request')}</h1>
                    <div className="reset-password-hr"></div>
                    {showEmailSentScreen ?   
                        <div className="password-reset-email-instructions">
                            <FaCheckCircle className="checkcircle"/>
                            <span>{t('reset_continue_text')}</span>
                        </div>
                    :
                        <form className="reset-password-form" onSubmit={handleSendResetEmail}>
                            <div className="reset-password-form-item-cont">
                                <label className="reset-password-input-label" htmlFor="email">{t('email')}</label>
                                <input value={email} required={true} className="reset-password-input" placeholder={t("email_placeholder")} name="email" onChange={(e) => {setEmail(e.target.value)}} id="email" type="text" />
                            </div>
                            <div className="recaptcha-cont">
                                <ReCAPTCHA size="invisible" ref={recaptchaRef} sitekey={localConfig.captcha} />
                            </div>
                            <button disabled={isLoading || pendingCaptcha} className="login-button">{t('reset_password_request_button')}</button>
                        </form>
                    }
                    <div className="reset-password-links-cont">
                        <Link className="reset-password-link" to="/login">{t('back_to_login')}</Link>
                    </div>
                </div>
            )}
        </>
    )
}

export default ResetPasswordRequest