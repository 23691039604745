import axiosInstance from "../api/axiosInstance"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

const fetchWallets = () => {
    return axiosInstance.post("/getwallets/")
}

const postWallet = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/create_new_wallet/", formData);
};

const postWalletWithdraw = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/wallet_withdrawal/", formData);
};

const postWalletWithdrawConfirmation = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/withdrawal/confirm-withdrawal-request", formData);
};

const postWalletWithdrawResendConfirmation = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/withdrawal/resend-withdrawal-request", formData);
};

const postWalletWithdrawCancel = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/withdrawal/cancel-withdrawal-request", formData);
};


export const useWallets = () => {
    return useQuery({
        queryKey: ['wallets'],
        queryFn: fetchWallets,
        select: (data) => {
            return data.data
        }
    })
}

export const usePostWallet = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postWallet(formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wallets'] })
        }
    });
}

export const usePostWalletWithdraw = () => {
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postWalletWithdraw(formData),
    });
}

export const usePostWalletWithdrawConfirmation = () => {
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postWalletWithdrawConfirmation(formData),
    });
}

export const usePostWalletWithdrawResendConfirmation = () => {
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postWalletWithdrawResendConfirmation(formData),
    });
}

export const usePostWalletWithdrawCancel= () => {
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postWalletWithdrawCancel(formData),
    });
}