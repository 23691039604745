import axiosInstance from "../api/axiosInstance";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

/**
 * /exchange/ handles cryptocurrency exchange operations.
 * 
 * - `GET` to retrieve a user's exchange history.
 * - `PUT` to calculate and return the cost and price of a potential operation without executing it.
 * - `POST` to create a new exchange operation.
 * 
 */

interface OperationPayload {
    base_currency: string;
    quote_currency: string;
    operation: number;
    quantity: string;
}

interface QueryParams {
    limit: number;
    offset: number;
}

const fetchExchange = (queryParams: QueryParams) => {
    return axiosInstance.get("/exchange/", { params: queryParams })
}

const postExchange = (operationData: OperationPayload) => {
    return axiosInstance.post("/exchange/", operationData);
}

const putExchange = (operationData: OperationPayload) => {
    return axiosInstance.put("/exchange/", operationData);
}

export const useExchange = (queryParams: QueryParams) => {
    return useQuery({
        queryKey: ['trades', queryParams],
        queryFn: () => fetchExchange(queryParams),
        select: (data) => {
            return data.data;
        }
    });
}

export const usePutExchange = (handleError: (error: any) => void) => {

    return useMutation({
        mutationFn: (operationData: OperationPayload) => putExchange(operationData),
        onError: (error) => {
            handleError(error)
        }
    });
}

export const usePostExchange = (handleSuccess: (payload: any) => void, handleError: (error: any) => void) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (operationData: OperationPayload) => postExchange(operationData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['balance'] })
            queryClient.invalidateQueries({ queryKey: ['trades'] })
            handleSuccess(data.data)
        },
        onError: (error) => {
            handleError(error)
        }
    });
}



