import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "../../routes/routes";


const DynamicTitle = () => {

    const { t } = useTranslation('common')

    const location = useLocation()

    const validRoutes = Object.values(routes).filter((route) => (route !== "*"))
    const routeStrings = validRoutes.map((route) => {
        return route.split('/')[1]
    })

    const route = location.pathname.split('/')[1]

    return (
        <Helmet>
            {routeStrings.includes(route) ? <title>{t(`title.${route}`)} | NEMO Latam</title> : <title>{t(`title.default`)} | NEMO Latam</title>}
        </Helmet>
    )
}

export default DynamicTitle;
