import { useTranslation } from "react-i18next";
import "./LanguageSelector.css";
import { useEffect, useState } from "react";

const LanguageSelector = () => {
    const { i18n } = useTranslation('common');
    const [languageSelected, setLanguageSelected] = useState(i18n.language);

    const changeLanguage = () => {
        setLanguageSelected(languageSelected === "es" ? "en" : "es");
    };

    useEffect(() => {
        i18n.changeLanguage(languageSelected);
        localStorage.setItem("language", languageSelected);
    },[languageSelected])

    return (
        <div className="lang-selector-cont" onClick={() => changeLanguage()}>
            <div 
                className={`${languageSelected === 'es' ? 'lang-selector-selected' : ''}`} 
            >
                ES
            </div>
            <div>
                <div className={`lang-selector-divider ${languageSelected === 'es' ? 'divider-selected' : ''}`}></div>
                <div className={`lang-selector-divider ${languageSelected === 'en' ? 'divider-selected' : ''}`}></div>
            </div>
            <div 
                className={`${languageSelected === 'en' ? 'lang-selector-selected' : ''}`} 
            >
                EN
            </div>
        </div>
    );
};

export default LanguageSelector;
