import { useState } from "react"
import PasswordEyeInput from "../PasswordEyeInput/PasswordEyeInput"
import { useTranslation } from "react-i18next"
import useAuthStore from "../../stores/AuthStore"
import toast from "react-hot-toast"
import "./ChangePassword.css"

const ChangePassword = () => {

    const { t } = useTranslation('common')

    const [resetPasswordFormData, setResetPasswordFormData] = useState({old_password: "", new_password1: "", new_password2: ""})

    const { changePasswordLogged, isLoading } = useAuthStore((state) => ({
        changePasswordLogged: state.changePasswordLogged,
        isLoading: state.isLoading
    }))
    
    const conditions = [
        { label: t("password_too_short"), regex: /.{8,}$/},
        { label: t("password_needs_uppercase"), regex: /[A-Z]/},
        { label: t("password_needs_lowercase"), regex: /[a-z]/},
        { label: t("password_needs_number"), regex: /[0-9]/},
        { label: t("password_needs_special"), regex: /[!@#$%^&*(),.?":{}|<>_\-+=\[\]\\\/`~;]/},
    ]

    const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setResetPasswordFormData((prev) => ({ ...prev, [name]: value }))
    }

    const handleChangePassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        for(let i = 0; i < conditions.length; i++) {
            if(!conditions[i].regex.test(resetPasswordFormData.new_password1)) {
                toast.dismiss()
                toast.error(t('hinting_regex_error'))
                return
            }
        }
        if (resetPasswordFormData.new_password1 !== resetPasswordFormData.new_password2) {
            toast.dismiss()
            toast.error(t("password_mismatch"))
            return
        }
        if(await changePasswordLogged(resetPasswordFormData) === "success") {
            console.log("success")
            setResetPasswordFormData({old_password: "", new_password1: "", new_password2: ""})
        }
    }

    return (
        <form onSubmit={handleChangePassword} className="change-password-cont">
            <div className="password-reset-label-input-cont">
                <label htmlFor="old_password" className="password-reset-label" >{t('old_password')}</label>
                <PasswordEyeInput i_value={resetPasswordFormData.old_password} i_placeholder="old_password_placeholder" i_name="old_password" i_id="old_password" handleChangeForm={handleChangeForm} />
            </div>
            <div className="password-reset-label-input-cont">
                <label htmlFor="new_password1" className="password-reset-label" >{t('new_password')}</label>
                <PasswordEyeInput i_value={resetPasswordFormData.new_password1} i_placeholder="new_password_placeholder" i_name="new_password1" i_id="new_password1" handleChangeForm={handleChangeForm} hasHinting={true} hintingConditions={conditions} />
            </div>
            <div className="password-reset-label-input-cont">
                <label htmlFor="new_password2" className="password-reset-label" >{t('confirm_new_password')}</label>
                <PasswordEyeInput i_value={resetPasswordFormData.new_password2} i_placeholder="confirm_password_placeholder" i_name="new_password2" i_id="new_password2" handleChangeForm={handleChangeForm} />
            </div>
            <button disabled={isLoading} className="change-password-submit-button">{t('change_password')}</button>
        </form>
    )
}

export default ChangePassword