import { useTranslation } from "react-i18next"
import nemocard from "../../assets/logos/nemocard.svg"
import "./NemoCard.css"


const NemoCard = () => {

    const { t } = useTranslation('common')

    return (
        <div className="nemo-card-main-cont">
            <div className="nemo-card-body">
                <img className="card-img" src={nemocard} />
                <span className="card-active">{t('active')}</span>
            </div>
        </div>
    )
}

export default NemoCard