import axiosInstance from "../api/axiosInstance"
import { useQuery } from "@tanstack/react-query"

const fetchCoins = () => {
    return axiosInstance.get("/coins")
}

export const useCoins = () => {
    return useQuery({
        queryKey: ['coins'],
        queryFn: fetchCoins,
        select: (data) => {
            return data.data
        },
        refetchInterval: 5000,
    })
}