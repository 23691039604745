import axiosInstance from "../api/axiosInstance"
import { useQuery } from "@tanstack/react-query"

const fetchPairs = () => {
    return axiosInstance.get("/pairs")
}

export const usePairs = () => {
    return useQuery({
        queryKey: ['pairs'],
        queryFn: fetchPairs,
        select: (data) => {
            return data.data.pairs
        }
    })
}