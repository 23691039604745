import usePageLayoutStore from "../../stores/PageLayoutStore"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import { BiSolidTrafficCone } from "react-icons/bi";
import "./Maintenance.css"

const Maintenance = () => {

    const { t } = useTranslation('common')

    const { isMaintenance } = usePageLayoutStore((state) => ({
        isMaintenance: state.isMaintenance
    }))

    return (
        <>
          {isMaintenance ? 
                <div className="maintenance-main-cont">
                    <h2>{t('website_under_maintenance')}</h2>
                    <BiSolidTrafficCone className="maintenance-cone" />
                </div>
            :
                <Navigate to="/" />
            }
        </>
    )
}

export default Maintenance