import React, { useState, useRef } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import useAuthStore from "../../stores/AuthStore";
import { useTranslation } from "react-i18next";
import PasswordEyeInput from "../../components/PasswordEyeInput/PasswordEyeInput";
import { Link } from "react-router-dom";
import toast from 'react-hot-toast'
import terms from "../../assets/docs/terms.pdf"
import data_treatment from "../../assets/docs/data_treatment.pdf"
import goods_declaration from "../../assets/docs/goods_declaration.pdf"
import data_authorization from "../../assets/docs/data_authorization.pdf"
import ReCAPTCHA from 'react-google-recaptcha';
import logo from "../../assets/logos/logo.svg"
import "./Register.css";
import localConfig from '../../../src/local_config';

interface PartialAccountInfo {
    email: string,
    password: string,
    password1: string,
}

interface CheckboxConfig {
    inside_jsx: JSX.Element,
    handleToggle: (e: React.MouseEvent<HTMLDivElement>) => void,
    flag: boolean
}

const Register = () => {
    const { t } = useTranslation('common');

    const nav = useNavigate()

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const { registerAccount, isLoading } = useAuthStore((state) => ({
        registerAccount: state.registerAccount,
        isLoading: state.isLoading
    }));

    const [formData, setFormData] = useState<PartialAccountInfo>({
        email: "",
        password: "",
        password1: "",
    });

    const [checkboxValues, setCheckboxValues] = useState({terms_privacy: false, goods: false, authorization: false})

    const [pendingCaptcha, setPendingCaptcha] = useState(false)

    const allchecked = Object.values(checkboxValues).every(item => item)

    const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        for(let i = 0; i < conditions.length; i++) {
            if(!conditions[i].regex.test(formData.password)) {
                toast.dismiss();
                toast.error(t('hinting_regex_error'))
                return;
            }
        }

        if (formData.password !== formData.password1) {
            toast.dismiss();
            toast.error(t("password_mismatch"))
            return
        }
        setPendingCaptcha(true)
        const token = await recaptchaRef.current?.executeAsync()
        setPendingCaptcha(false)
        if(token) {
            if(await registerAccount({...formData, captchaResponse: token, username: formData.email, password2: formData.password, lang: "es" })) {
                nav(`/confirm-register/${formData.email}`)
            }
        }
        else {
            toast.dismiss()
            toast.error(t("captcha_failed"))
        }
    };

    const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const { isLogged } = useAuthStore((state) => ({
        isLogged: state.isLogged
    }));

    const conditions = [
        { label: t("password_too_short"), regex: /.{8,}$/},
        { label: t("password_needs_uppercase"), regex: /[A-Z]/},
        { label: t("password_needs_lowercase"), regex: /[a-z]/},
        { label: t("password_needs_number"), regex: /[0-9]/},
        { label: t("password_needs_special"), regex: /[!@#$%^&*(),.?":{}|<>_\-+=\[\]\\\/`~;]/},
    ]

    const handleToogleCheckbox = (e: React.MouseEvent<HTMLDivElement>, name: "goods" | "terms_privacy" | "authorization") => {
        const target = e.target as HTMLElement;
        if(target.id === "anchor") {
            return
        }
        setCheckboxValues((prev) => {
            return {...prev, [name]: !prev[name]}
        })
    }

    const checkboxes = [
        {inside_jsx: <div>{t('terms_text1')} <a className="document-anchor" id="anchor" target="_blank" href={terms}>{t('terms_and_conditions')}</a> & <a className="document-anchor" id="anchor" target="_blank" href={data_treatment}>{t('data_processing_policy')}</a> {t('terms_text2')}</div>, handleToggle: (e: React.MouseEvent<HTMLDivElement>) => {handleToogleCheckbox(e, "terms_privacy")}, flag: checkboxValues.terms_privacy},
        {inside_jsx: <div>{t('declaration_text1')} <a className="document-anchor" id="anchor" target="_blank" href={goods_declaration}>{t('goods_declaration')}</a></div>, handleToggle: (e: React.MouseEvent<HTMLDivElement>) => {handleToogleCheckbox(e, "goods")}, flag: checkboxValues.goods},
        {inside_jsx: <div>{t('authorization_text1')} <a className="document-anchor" id="anchor" target="_blank" href={data_authorization}>{t('data_authorization')}</a> {t('authorization_text2')}</div>, handleToggle: (e: React.MouseEvent<HTMLDivElement>) => {handleToogleCheckbox(e, "authorization")}, flag: checkboxValues.authorization},
    ]


    const mappedCheckboxes = checkboxes.map((conf: CheckboxConfig, id) => {
        return <div key={id} className="checkbox-main-cont">
            <div onClick={conf.handleToggle} className={conf.flag ? "checkbox cont-checked" : "checkbox cont-unchecked"}>
                <div className={conf.flag ? "checkbox-ball ball-checked" : "checkbox-ball ball-unchecked"}></div>
            </div>
            <div onClick={conf.handleToggle} className="checkbox-jsx-cont">
                {conf.inside_jsx}
            </div>
        </div>
    })

    return (
        <>
            {isLogged ? (
                <Navigate to="/" />
            ) : (
                <div className="register-main-cont">
                    <img className="not-logged-logo" src={logo} />
                    <h1 className="register-title">{t('register')}</h1>
                    <div className="register-hr"></div>
                    <form className="register-form" onSubmit={handleRegister}>
                        <div className="register-form-item-cont">
                            <label className="register-input-label" htmlFor="email">{t('email')}</label>
                            <input required={true} className="register-input" placeholder={t("email_placeholder")} name="email" onChange={handleChangeForm} id="email" type="email" />
                        </div>
                        <div className="register-form-item-cont">
                            <label className="register-input-label" htmlFor="password">{t('password')}</label>
                            <PasswordEyeInput notLogged={true} i_value={formData.password} i_placeholder="password_placeholder" i_name="password" i_id="password" handleChangeForm={handleChangeForm} hasHinting={true} hintingConditions={conditions} />
                        </div>
                        <div className="register-form-item-cont">
                            <label className="register-input-label" htmlFor="password1">{t('confirm_password')}</label>
                            <PasswordEyeInput notLogged={true} i_value={formData.password1} i_placeholder="confirm_password" i_name="password1" i_id="password1" handleChangeForm={handleChangeForm} />
                        </div>
                        <div className="checkboxes-cont">
                            {mappedCheckboxes}
                        </div>
                        <div className="recaptcha-cont">
                            <ReCAPTCHA size="invisible" ref={recaptchaRef} sitekey={localConfig.captcha} />
                        </div>
                        <button disabled={isLoading || pendingCaptcha || !allchecked} className="register-button">{t('register')}</button>
                    </form>
                    <div className="register-links-cont">
                        <Link className="register-link" to="/login">{t('already_have_account')}</Link>
                        <Link className="register-link" to="/reset-password">{t('forgot_password')}</Link>
                    </div>
                </div>
            )}
        </>
    )
}

export default Register;
